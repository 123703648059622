<template>
  <div>
    <b-overlay center :show="isLoading" rounded="sm">
      <div v-if="!isLoading" class="mx-1">
        <h6>Titoli da incassare</h6>
        <export-options
            :exportUrl="exportUrl"
            :repository="repository"
            :resource="resource"
            :tableRef="$refs[tableRef]"
            :filter="filter"
            :options="[
        {
          code: 'EXPOTIPOL',
          label: null,
          formats: ['csv', 'pdf'],
        },
      ]"
        ></export-options>
        <insurance-ancillaries
            :fields="fields"
            :repository="repository"
            :resource="resource"
            :filterName="filterName1"
            ref="policyTitlesDetailRef1"
            noActions
            noInnerWidth
            sortField="delay_until"
            :filterOn="{
            byRelations: ['byBroker', 'byBookEntry', 'byInsurerParticipant'],
          }"
            @rowSelector="onAddBookEntry"
            includeRowSelector
            noInputPaginator
        ></insurance-ancillaries>
        <h6>Altri Titoli</h6>
        <export-options
            :exportUrl="exportUrl"
            :repository="repository"
            :resource="resource"
            :tableRef="$refs[tableRef]"
            :filter="filter"
            :options="[
        {
          code: 'EXPOATPOL',
          label: null,
          formats: ['csv', 'pdf'],
        },
      ]"
        ></export-options>
        <insurance-ancillaries
            :fields="fields"
            :repository="repository"
            :resource="resource"
            :filterName="filterName2"
            ref="policyTitlesDetailTableRef2"
            noActions
            noInnerWidth
            sortField="delay_until"
            :filterOn="{
            byRelations: ['byBroker', 'byBookEntry', 'byInsurerParticipant'],
          }"
            @rowSelector="onAddBookEntry"
            includeRowSelector
            noInputPaginator
        ></insurance-ancillaries>
      </div>
      <template #overlay>
        <div class="text-center mt-5">
          <base-icon name="loading" width="35" height="35"/>
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import BaseIcon from "@/components/BaseIcon";
import ExportOptions from "@/components/ExportOptions";
import InsuranceAncillaries from "@/components/tables/InsuranceAncillaries";
import {toLocaleCurrency} from "@/utils/strings";
import {toLocaleDate} from "@/utils/dates";
import {mapActions} from "vuex";
import {getDictionary} from "@/utils/dictionary";

export default {
  name: "Titles",
  props: {
    resourceId: Number,
  },
  data() {
    return {
      exportUrl: null,
      isLoading: false,
      filter: {
        filterPolicyTitlesDetail1: this.initFilter1(),
        filterPolicyTitlesDetail2: this.initFilter2(),
      },
      filterName1: "filterPolicyTitlesDetail1",
      filterName2: "filterPolicyTitlesDetail2",
      repository: "insurance_ancillary",
      resource: "insurance_ancillaries",
      tableRef: {
        filterPolicyTitlesDetail1: "policyTitlesDetailRef1",
        filterPolicyTitlesDetail2: "policyTitlesDetailTableRef2",
      },
      fields: [
        {
          key: "rowSelector",
          label: "Incassa",
          sortable: false,
          thStyle: {
            width: "20px",
          },
        },
        {
          key: "insurance_policy.insurer.title",
          label: this.getDictionary("title", "insurer"),
          sortable: false,
        },
        {
          key: "insurance_policy.number",
          label: this.getDictionary("number", "insurance_policy"),
          sortable: false,
        },
        {
          key: "effective_at",
          label: this.getDictionary("effective_at", "insurance_ancillary"),
          sortable: true,
          sortKey: "effective_at",
          formatter: (value) => toLocaleDate(value),
        },
        {
          key: "delay_until",
          formatter: (value) => toLocaleDate(value),
          sortable: true,
          sortKey: "delay_until",
          label: this.getDictionary("delay_until", "insurance_ancillary"),
        },
        {
          key: "code.value",
          label: this.getDictionary("code", "insurance_ancillary"),
          sortable: false,
        },
        {
          key: "insurance_policy.insurance_risk.risk_branch.title",
          label: this.getDictionary("title", "risk_branch"),
          sortable: false,
        },
        {
          key: "insurance_policy.insurance_risk.title",
          label: this.getDictionary("insurance_risk"),
          sortable: false,
        },
        {
          key: "net",
          label: this.getDictionary("net", "insurance_ancillary"),
          sortable: false,
          class: "text-center",
          formatter: (value) => toLocaleCurrency(value),
          thClass: "text-right",
          thStyle: {
            "text-align": "right",
          },
          tdClass: "text-right",
        },
        {
          key: "tax",
          label: this.getDictionary("tax", "insurance_ancillary"),
          sortable: false,
          class: "text-center",
          formatter: (value) => toLocaleCurrency(value),
          thClass: "text-right",
          thStyle: {
            "text-align": "right",
          },
          tdClass: "text-right",
        },
        {
          key: "gross",
          label: this.getDictionary("gross", "insurance_ancillary"),
          sortable: false,
          class: "text-center",
          formatter: (value) => toLocaleCurrency(value),
          thClass: "text-right",
          thStyle: {
            "text-align": "right",
          },
          tdClass: "text-right",
        },
        {
          key: "status_ancillary.text",
          label: this.getDictionary("status_ancillary", "insurance_ancillary"),
          sortable: false,
        },
      ],
    };
  },
  components: {
    BaseIcon,
    ExportOptions,
    InsuranceAncillaries,
  },
  methods: {
    getDictionary,
    initFilter1() {
      let init = {
        byInsurancePolicy: {id: this.resourceId},
        byAttribute: {status_ancillary: 0, code: "!X"},
      };
      return init;
    },
    initFilter2() {
      let init = {
        byInsurancePolicy: {id: this.resourceId},
        byAttribute: {status_ancillary: "!0", code: "!X"},
      };
      return init;
    },
    onAddBookEntry(row) {
      this.$router.push({
        name: `book_entries.create`,
        params: {
          mode: "IV",
          titleId: row.id,
          insuranceRiskId: row.insurance_risk_id,
        },
      });
    },
    onSearch(name) {
      let criteria = this.filter[name];
      this.saveFilterByName({name, criteria});
      this.$refs[this.tableRef[name]]
          .fetch()
          .then(() => {
            this.removeFilterByName(name);
          })
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({preset: "error", text: errMsg});
          });
    },
    ...mapActions("filters", {
      saveFilterByName: "saveByName",
      removeFilterByName: "removeByName",
    }),
  },
  mounted() {
    this.onSearch(this.filterName1);
    this.onSearch(this.filterName2);
  },
  computed: {
    agencyHasFee() {
      return this.$store.state.auth.use_fee === "Y" ? true : false;
    },
  },
  created() {
    if (this.agencyHasFee) {
      const feeGrossField = {
        key: "fee_gross",
        label: this.getDictionary("fee_gross", "insurance_ancillary"),
        formatter: (value) => toLocaleCurrency(value),
        sortable: true,
        thClass: "text-right",
        thStyle: {
          "text-align": "right",
        },
        tdClass: "text-right",
      };
      this.fields.splice(12, 0, feeGrossField);
    }
  },
};
</script>
